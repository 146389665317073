<template>
  <div class="flex mt-4 px-6 pt-4 pb-9">
    <system-admin-side-bar class="pr-6 pl-4 flex-shrink-0" />
    <component
      :is="selectedOption.componentName"
      class="pl-6 w-full overflow-x-auto"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import useSystemAdminSideBar from '@/components/system-admin-sections/systemAdminSidebar.js';

export default {
    name: 'BASystemAdmininstration',

    components: {
        SystemAdminSideBar: defineAsyncComponent(() => import('@/components/system-admin-sections/SystemAdminSideBar.vue')),
        ContractSync: defineAsyncComponent(() => import('@/components/system-admin-sections/ContractSync.vue')),
        ManualSkuPush: defineAsyncComponent(() => import('@/components/system-admin-sections/ManualSkuPush.vue')),
        ResendNotification: defineAsyncComponent(() => import('@/components/system-admin-sections/ResendNotification.vue')),
        AutoAssignment: defineAsyncComponent(() => import('@/components/system-admin-sections/AutoAssignment.vue')),
        UserPermissions: defineAsyncComponent(() => import('@/components/system-admin-sections/UserPermissions.vue')),
        EnforceSample: defineAsyncComponent(() => import('@/components/system-admin-sections/EnforceSample.vue')),
    },

    setup () {
        const { selectedOption, setSelectedOption, sideBarOptions } = useSystemAdminSideBar();
        setSelectedOption(sideBarOptions.value[0]);

        return {
            selectedOption,
            setSelectedOption
        };
    }
};
</script>
